// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// export const environment = {
//     production: false,
//     googleMapsApiKey: '',
//     API_URL: 'https://tf-api.kumorai.io/api/',
//     WORKER_URL: 'https://tf-worker.kumorai.io/',
//     CLIENT_URL: 'https://tf-portal.kumorai.io/',
//     CLIENT_ID: '41fc09cf-4789-4f63-a565-7e880a3151b6',
//     TENANT_ID: '0b031fc9-84e1-487c-945f-e6dbd618cd02',
//     FILE_NAME: 'local-environment'
// };
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: true,
    googleMapsApiKey: '',
    API_URL: 'https://tfprod-api.kumorai.io/api/',
    WORKER_URL: 'https://tfprod-worker.kumorai.io/',
    CLIENT_URL: 'https://tfprod-portal.kumorai.io/',
    CLIENT_ID: '41fc09cf-4789-4f63-a565-7e880a3151b6',
    TENANT_ID: '0b031fc9-84e1-487c-945f-e6dbd618cd02',
    FILE_NAME: 'production-environment',
    CLIENT_NAME: 'TaylorFarms'
};
// export const environment = {
//     production: false,
//     googleMapsApiKey: '',
//     // API_URL: 'https://tf-api.kumorai.io/api/',
//     API_URL: 'http://localhost:7071/api/',
//     WORKER_URL: 'http://localhost:5050/',
//     CLIENT_URL: 'http://localhost:4200/',
//     CLIENT_ID: '0b39ac02-f8ed-4d5f-9a80-c442c917b69c',
//     TENANT_ID: 'e625bc9e-ebc6-4f19-9ba3-fddcaa2f4905',
//     FILE_NAME: 'local-environment'
// };